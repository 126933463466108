<template>
  <b-dropdown
    id="dropdown-grouped"
    v-ripple.400="'rgba(255, 255, 255, 0.4)'"
    :class="isNav ? 'd-md-inline-flex d-none' : ''"
    variant="link"
    right
  >
    <template #button-content>
      <span
        v-if="currentLocale"
        class="ml-50 text-dark"
      >
        {{ currentLocale.name }}
      </span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.key"
      @click="$i18n.locale = localeObj.key, assignLocale(localeObj.key)"
    >
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import esLocale from 'element-ui/lib/locale/lang/es'
import ElementLocale from 'element-ui/lib/locale'

import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { isUserLoggedIn } from '@/auth/utils'
import { mapActions } from 'vuex'
import languageService from '@/services/languageService'
import Ripple from 'vue-ripple-directive'

Vue.use(VueI18n)

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },

  directives: {
    Ripple,
  },

  props: {
    isNav: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      applyAccess: false,
      locales: [],
      filters: {
        page: 1,
        perPage: 15,
        locale: null,
        status: null,
        search: null,
      },
    }
  },

  computed: {
    currentLocale() {
      return this.locales.find(l => l.key === this.$i18n.locale)
    },
  },

  // watch: {
  //   'currentLocale.key': function currentLocale(val) {
  //     if (this.applyAccess && this.$route.name !== 'news') {
  //       this.filters.locale = val
  //       // this.fetchNews(this.filters)
  //     }
  //   },
  // },

  watch: {
    '$i18n.locale': function (pVal) {
      this.assigLocaleToElement(pVal)
    },
  },

  created() {
    this.applyAccess = isUserLoggedIn()

    if (this.applyAccess) {
      const keyLocale = localStorage.hasOwnProperty('locale') ? localStorage.getItem('locale') : 'es'
      localize(keyLocale)
      languageService.switchLanguage(keyLocale).then(response => {
        this.$i18n.locale = keyLocale
        this.locales = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    } else {
      this.$i18n.locale = 'es'
      localStorage.setItem('locale', 'es')
      languageService.getLanguages('es').then(response => {
        this.locales = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    }
  },

  mounted() {
    this.assigLocaleToElement(this.$i18n.locale)
  },

  methods: {
    ...mapActions({
      // fetchNews: 'news/fetchNews',
    }),

    assignLocale(plocale) {
      this.applyAccess = isUserLoggedIn()

      if (this.applyAccess) {
        languageService.switchLanguage(plocale).then(response => {
          localize(plocale)
          this.locales = response.data.data
          localStorage.setItem('locale', plocale)
        }).catch(error => {
          this.responseCatch(error)
        })
      } else {
        languageService.getLanguages(plocale).then(response => {
          localize(plocale)
          this.locales = response.data.data
          localStorage.setItem('locale', plocale)
        }).catch(error => {
          this.responseCatch(error)
        })
      }
    },

    assigLocaleToElement(pCurrent) {
      const messagesEl = {
        en: {
          ...enLocale,
        },
        es: {
          ...esLocale,
        },
      }

      const i18n = new VueI18n({
        locale: pCurrent,
        fallbackLocale: 'en',
        messages: messagesEl,
      })

      ElementLocale.i18n((key, value) => i18n.t(key, value))
    },
  },
}
</script>
