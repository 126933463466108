import apiInstance from './index'

export default {
  async getLanguages(locale) {
    return await apiInstance.get(`languages/${locale}`)
  },

  async switchLanguage(locale) {
    return await apiInstance.get(`switch-lang/${locale}`)
  },
}
