var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0 i-login"},[_c('div',{staticClass:"i-brand-logo"},[_c('light-vertical-ostrail-logo')],1),(_vm.showConfirmAgreeToTerms)?_c('div',[_c('terms-and-conditions',{attrs:{"show-spinner-login":_vm.showSpinnerLogin},on:{"assign-actions":_vm.assignActions}})],1):(_vm.showConfirmValidation)?_c('div',{staticClass:"mt-1"},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('loginMessages.verifyYourEmail'))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('loginMessages.sentAnEmail'))+" "),_c('strong',[_vm._v("\""+_vm._s(_vm.formLogin.email)+"\"")]),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('loginMessages.needVerify'))+" "+_vm._s(_vm.$t('loginMessages.spamFolder'))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('loginMessages.clickTheResend'))+" ")]),_c('validation-observer',{ref:"codeValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationCode.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('loginMessages.securityCode'),"label-for":"sec-code"}},[_c('validation-provider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sec-code","state":errors.length > 0 ? false : null,"name":"sec-code","placeholder":"34af2w"},model:{value:(_vm.secCode),callback:function ($$v) {_vm.secCode=$$v},expression:"secCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"type":"submit","variant":"dark","block":"","pill":"","disabled":_vm.showSpinnerLogin}},[(_vm.showSpinnerLogin)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',{staticClass:"ml-spinner"},[_vm._v(_vm._s(_vm.$t('loginMessages.continue')))])],1)],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{on:{"click":function($event){return _vm.showMessage()}}},[_c('i',{staticClass:"icon-0-icons-dark-repeat"}),_vm._v(" "+_vm._s(_vm.$t('loginMessages.resend'))+" ")])],1),_c('br')],1):_c('div',[_c('b-navbar-nav',{staticClass:"nav align-items-center ml-auto align-content-end mb-2"},[_c('locale',{attrs:{"is-nav":false}})],1),_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('loginMessages.loginWelcome'))+" OStrail! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('loginMessages.loginSignIn'))+" ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.tableHeader.email'),"label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"john@ostrail.com"},model:{value:(_vm.formLogin.email),callback:function ($$v) {_vm.$set(_vm.formLogin, "email", $$v)},expression:"formLogin.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v(" "+_vm._s(_vm.$t('loginMessages.password'))+" ")]),_c('b-link',{attrs:{"to":{ name:'auth-forgot-password' }}},[_c('small',[_vm._v(_vm._s(_vm.$t('loginMessages.forgotPassword')))])])],1),_c('validation-provider',{attrs:{"name":"password","vid":"password","rules":"min:8|required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Password"},model:{value:(_vm.formLogin.password),callback:function ($$v) {_vm.$set(_vm.formLogin, "password", $$v)},expression:"formLogin.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"dark","block":"","pill":"","disabled":invalid || _vm.showSpinnerLogin}},[(_vm.showSpinnerLogin)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',{staticClass:"ml-spinner"},[_vm._v(_vm._s(_vm.$t('loginMessages.signIn')))])],1),_c('br')],1)]}}])})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }