<template>
  <div class="mt-1">
    <b-card-title class="mb-1">
      {{ $t('accountSettings.termsAndConditionsTwo') }}
    </b-card-title>
    <b-card-text class="mb-2 i-text-card-t-and-c i-scroll-t-and-c">
      <strong> Información Relevante Demo </strong>
      <p>
        Es requisito necesario para la adquisición de los servicios que se ofrecen en este sitio, que lea
        y acepte los siguientes Términos y Condiciones que a continuación se redactan. El uso de
        nuestros servicios implicará que usted ha leído y aceptado los Términos y Condiciones de Uso
        en el presente documento. Todas los servicios que son ofrecidos por nuestro sitio web
        pudieran ser creadas, cobradas, enviadas o presentadas por una página web tercera y en tal
        caso estarían sujetas a sus propios Términos y Condiciones. En algunos casos, para adquirir
        un servicio, será necesario el registro por parte del usuario, con ingreso de datos personales
        fidedignos y definición de una contraseña.

        El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en
        cualquier momento, en caso de que se haya registrado y que sea necesario para la solicitud
        de alguno de nuestros servicios.

        Todas las compras y transacciones que se lleven a cabo por medio de este sitio web, están
        sujetas a un proceso de confirmación y verificación, el cual podría incluir la verificación y
        disponibilidad de producto, validación de la forma de pago, validación de la factura (en caso de
        existir) y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En
        algunos casos puede que se requiera una verificación por medio de correo electrónico.
        Los precios de los servicios ofrecidos en este Sitio Web es válido solamente en las compras
        realizadas en este sitio web.
      </p>
      <br>
      <strong>Licencia Demo</strong>
      <p>
        OStrail a través de su sitio web concede una licencia específica por un lapso
        de tiempo definido para que los usuarios utilicen los servicios con el fin de lograr la
        acreditación de acuerdo a los Términos y Condiciones que se describen en este documento.
      </p>
    </b-card-text>
    <b-form-checkbox
      v-model="agreeToTerms"
      :value="!agreeToTerms"
      class="custom-control-primary"
    >
      {{ $t('accountSettings.agreeToTheTerms') }}
      <span class="text-primary">
        {{ $t('accountSettings.termsAndConditions') }}
      </span>
    </b-form-checkbox>
    <b-button
      class="mt-1"
      variant="dark"
      block
      pill
      :disabled="agreeToTerms == false || showSpinnerLogin"
      @click="assignAgreeToTerms"
    >
      <b-spinner
        v-if="showSpinnerLogin"
        small
      />
      <span class="ml-spinner">{{ $t('generic.accept') }}</span>
    </b-button>
    <p class="text-center mt-2">
      <b-link @click="$emit('assign-actions', false)">
        <i class="icon-0-icons-dark-cross i-fs-icon" />
        {{ $t('buttons.decline') }}
      </b-link>
    </p>
    <br>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BCardTitle, BCardText, BButton, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BButton,
    BCardTitle,
    BCardText,
    BFormCheckbox,
    BSpinner,
  },

  props: {
    showSpinnerLogin: { Boolean },
  },

  data() {
    return {
      agreeToTerms: false,
    }
  },

  methods: {
    assignAgreeToTerms() {
      this.$emit('assign-actions', true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.i-text-card-t-and-c {
  text-align: justify;
}

/* Scroll Firefox */
.i-scroll-t-and-c {
  scrollbar-width: thin;
  scrollbar-color:  rgba(0, 0, 0, 0.15) #f6f8fb;
}

/* Scroll Chrome, Edge, and Safari */
.i-scroll-t-and-c::-webkit-scrollbar {
  height: 12px;
  width: 8px;
}

.i-scroll-t-and-c::-webkit-scrollbar-track {
  background: #f6f8fb;
}

.i-scroll-t-and-c::-webkit-scrollbar-thumb {
  background-color:  rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  border: 3px solid #f6f8fb;
}

.i-scroll-t-and-c {
  white-space: initial;
  display: block !important;
  flex-wrap: nowrap;
  height: 300px;
  overflow-x: hidden;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
  padding-right: 5px;
}

.i-fs-icon {
  font-size: 10px;
}
</style>
